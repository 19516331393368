import React, { useState, useEffect, Component } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Rating from 'react-rating';

import Footer from '../../components/Footer/Footer';
import Map from '../../components/Map';

import { Consumer } from "../../context";
import loadGift from "../../img/load.gif";
import ModalZoom from "../../components/modalZoom";

import Request from "../../core/httpClient";
const request = new Request();

var qs = require('qs');

const Micrositios = (props, context) => {

	const params = useParams();
	const location = useLocation();
	const history = useHistory();

	const [microsite, setMicrosite] = useState({});
	const [publicidad, setPublicidad] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		obtenerMicrositio();
	}, []);

	useEffect(() => {

		if (microsite.id_tienda) {

			registrarVisita();

		};

	}, [microsite]);

	async function obtenerMicrositio() {
		setLoading(true);

		// muestra overlay

		props.context.setZoom(loadGift, false);
		const modal = document.getElementById("image-zoom-modal");

		if (modal) {
			modal.classList.remove("fadeIn");
			modal.style.display = "flex";
		}

		const { id } = params;

		const data = { NombreUrl: id };

		const response = await request.get("/tiendas/detalle/get", data);

		if (response) {

			if (response[0]) {

				const micrositio = response[0];
				setMicrosite(micrositio);

				if (response[0].detalle !== null) {
					const publicidad = JSON.parse(response[0].detalle);
					setPublicidad(publicidad);
				};

			} else {
				window.location = '/404';
			};
		};

		setLoading(false);

		// cerramos overlay
		modal.classList.remove("fadeIn");
		modal.classList.add("animated");
		modal.classList.add("fadeOut");
		setTimeout(() => {
			modal.style.display = "none";
			modal.classList.remove("fadeOut");
			//this.close();
		}, 500);

		window.scrollTo(0, 0);
	};

	async function registrarVisita() {

		try {

			const { search } = location;

			const origen = qs.parse(search, { ignoreQueryPrefix: true }).origen
			const id_inquilino = qs.parse(search, { ignoreQueryPrefix: true }).id_inquilino
			const id_frecuente = qs.parse(search, { ignoreQueryPrefix: true }).id_frecuente

			if (origen) {

				let data = {
					id_tienda: microsite.id_tienda,
					id_inquilino: id_inquilino,
					id_frecuente: id_frecuente,
					origen: origen
				};

				history.replace({ search: '' });

				const response = await request.post("/tiendas/visita/add", data);				

			};

		} catch (error) {

		};
	};

	return (
		<div className="page-microsite full justify-center align-center">
			<img className="header-img"
				src={microsite.portada_url}
			/>
			<div className="container">
				<div className="row justify-center align-center full">
					<div id="introduccion">
						<div className="logo" style={{ backgroundImage: `url(${microsite.logo_url})` }}>
						</div>
					</div>
					<div className="descripcion">
						<section id="descripcion_imagen" className="column left-imagen">
							<img src={microsite.imagen_url} />
							<div className="white-space-24"></div>
							<div className="flex align-center justify-center">
								<a className="button" href={microsite.web}>
									VISITA NUESTRA PÁGINA WEB
								</a>
							</div>
						</section>
						<section className="desc-map column">
							<h2 className="title2 pink weight-regular">
								{microsite.lema}
							</h2>
							<p className="text-justify text">
								{microsite.descripcion}
							</p>
							{!loading && <div className="mapa">
								<Map latitud={microsite.latitud || 0} longitud={microsite.longitud || 0} />
							</div>}
						</section>
					</div>
					<div className="column full justify-center align-center">
						<div id="coleccion_imagenes">
							{publicidad.map(p => {
								return (
									<div key={p.id_tienda_detalle} className="imagen-producto">
										<img src={p.imagen_url} alt={p.descripcion} />
									</div>
								)
							})}
						</div>
					</div>
					<div id="calificaciones" className="column full justify-center align-center">
						<div className="title">
							Calificaciones y Opiniones
						</div>
						<br></br>
						<div className="calificacion">
							<span className="number">5.0</span>
							<Rating
								initialRating={5}
								readonly
								emptySymbol="fa fa-star-o fa-2x pink"
								fullSymbol="fa fa-star fa-2x pink"
								fractions={2} />
						</div>
					</div>
					<div id="contactanos">
						<div className="title2">VEN Y CONÓCENOS</div>
						<div className="white-space-8"></div>
						<div className="title3">¡No dejes pasar las promociones, visítanos!</div>
						<div className="white-space-8"></div>
						<div className="title4">INFORMACIÓN DE CONTACTO</div>
						<div className="cards">
							<a href={`mailto:${microsite.email}`} className="email">
								<i className="fa fa-envelope"></i>
								<div className="white-space-8"></div>
								{microsite.email}
							</a>
							<a href={`tel:${microsite.telefono}`} className="phone">
								<i className="fa fa-phone"></i>
								<div className="white-space-8"></div>
								{microsite.telefono}
							</a>
						</div>
						<div className="white-space-16"></div>
						<div className="title5">
							CONTÁCTANOS PARA MÁS INFORMACIÓN
						</div>
						<div className="white-space-32"></div>
					</div>
				</div>
				{microsite.whatsapp &&
					<a href={`https://wa.me/${microsite.whatsapp}`}>
						<div className="whatsapp">
							<i className="fa fa-whatsapp"></i>
						</div>
					</a>
				}
			</div>
			<Footer
				facebook={microsite.facebook_url}
				instagram={microsite.instagram_url}
				youtube={microsite.youtube_url}
			/>
			<ModalZoom />
		</div>
	);
};

export default Consumer(Micrositios);
