import React, { useState, useEffect } from 'react';
import { isMobileOnly, isTablet, isIOS } from "react-device-detect";
import Header from '../../components/Header';
import GalleryGrid from '../../components/gallery-grid';
import SelloISO from '../../img/SelloISO.png';
import Paginator from '../../components/paginator/paginator';
import Request from "../../core/httpClient";
const request = new Request();

const Home = () => {

    const [tiendas, setTiendas] = useState([]);

    const [ciudades, setCiudades] = useState([]);
    const [ciudad, setCiudadSelected] = useState('0');
    const [search, searching] = useState('');
    const [filter, updateFilter] = useState([]);
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategorySelected] = useState('0');
    const [itemsFilterCiudad, setItemsFilterCiudad] = useState([]);
    const [pagina, actualizarPagina] = useState(1);

    useEffect(() => {

        obtenerTiendas();

    }, []);

    useEffect(() => {

        const busqueda = items.filter(b => b.tags.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        updateFilter(busqueda);

    }, [search]);

    async function obtenerTiendas() {

        const response = await request.get("/tiendas/get/all");
        console.log(response);
        if (response) {

            setTiendas(response);
            const arrayItems = getItems(response);

            let ciudades = [], ciudad = 0;

            arrayItems.map(ai => {

                if (ciudades.length !== 0) {

                    ciudades.map(ciu => {

                        if (ai.ciudad === ciu.name) {
                            ciudad++;
                        };

                    });
                }

                if (ciudad === 0) {
                    ciudades.push({ name: ai.ciudad });
                };

            });

            console.log("Tiendas", arrayItems);

            setCiudades(ciudades);
            setItems(arrayItems);
            updateFilter(arrayItems);

        }
    };

    function getItems(array) {

        let arrayItems = [];

        if (Array.isArray(array)) {
			array.map(c => {
				arrayItems.push(c);
			});
		}

        return arrayItems;
    };

    function ciudadSelected(e) {

        setCiudadSelected(e.target.value);
        setCategorySelected('0');
        actualizarPagina(1);

        let categorias = [], category = 0, filtro = [];

        if (e.target.value !== '0') {

            const filtroCiudades = tiendas.filter(c => c.ciudad === e.target.value);

            filtro = getItems(filtroCiudades);

            filtro.map(fc => {

                if (categorias.length !== 0) {

                    categorias.map(cat => {

                        if (cat.name === fc.categoria) {
                            category++;
                        };
                    });
                };

                if (category === 0) {
                    categorias.push({ name: fc.categoria });
                } else {
                    category = 0;
                };

            });

        } else {
            filtro = getItems(tiendas);
        }

        updateFilter(filtro);
        setItemsFilterCiudad(filtro);
        setCategories(categorias);
    };

    function categorySelected(e) {

        setCategorySelected(e.target.value);
        let filtroCategorias = [];

        if (e.target.value !== '0') {

            filtroCategorias = itemsFilterCiudad.filter(c => c.categoria === e.target.value);

        } else {

            const filtroCiudades = ciudades.filter(c => c.nombre === ciudad);
            filtroCategorias = getItems(filtroCiudades);

        };

        updateFilter(filtroCategorias);
    };

    function setpage(page) {
        actualizarPagina(page);
    };

    function getAll(e) {
        e.preventDefault();
        setCategories([]);
        setCiudadSelected('0');
        updateFilter(items);
        actualizarPagina(1);
    };

    const enviarDatos = (event) => {
        event.preventDefault();
    };

    return (
        <div className="home column flex justify-center align-center">
            <Header />
            <div className="column align-center justify-center">
                <div className="busqueda column align-center justify-center">
                    <div className="white-space-64"></div>
                    <div className="tiendas-dest row-responsive justify-center align-center">
                        <div className="container">
                            <div className="cnt column align-center justify-center ">
                                <div className="tiendas justify-center align-center">TIENDAS DESTACADAS</div>
                                <div className="filters column align-center justify-start">
                                    <div className={`${isTablet ? 'container-tablet' : 'container-desk'} search__container row-responsive align-center justify-center`}>
                                        <form className="search_filter" onSubmit={enviarDatos} >
                                            <input className="search__input align-end justify-end" onChange={(e) => searching(e.target.value)} name="text" type="text" placeholder="¿qué te gustaría encontrar?" />
                                            <i className="fas fa-search"></i>
                                        </form>
                                        <div className={`${isTablet ? 'row filtro-tablet' : 'row-responsive filtro-desk'} filtro2`}>
                                            <form className={`${isTablet ? 'row selects-tablet' : 'row-responsive selects-desk'} search__form align-center justify-center`}>
                                                <div className="column">
                                                    <select className="categories " name="ciudades" id="ciudades" value={ciudad} onChange={ciudadSelected.bind(this)}> ?
                                                    <option className="categoria" value="0">Selecciona una ciudad</option>
                                                        {ciudades.map((item, index) =>
                                                            <option key={index} className="categoria" value={item.name}>{item.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                {categories.length !== 0 &&
                                                    <React.Fragment>
                                                        <div className="column">
                                                            <select className="categories2" name="categories" id="categories" value={category} onChange={categorySelected.bind(this)}> ?
                                                        <option className="categoria" value="0">Selecciona una categoría</option>
                                                                {categories.map((item, index) =>
                                                                    <option key={index} className="categoria" value={item.name}>{item.name}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </form>
                                            <form className="row-responsive buttons align-center justify-center">
                                                <div className="boton align-center justify-center">
                                                    <a className="botton" href="/" >
                                                        <button type="button" className="btn btn-aqua align-center justify-center" onClick={getAll.bind(this)} >VER TODO</button>
                                                    </a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="empresas column align-center justify-center">
                                    <div className="gallery justify-center">
                                        {filter.length !== 0 ?
                                            <GalleryGrid
                                                results={filter}
                                                page={pagina}
                                            />
                                            :
                                            <div className="resultados justify-center align-center">
                                                <h4 className="resultados">No se encontraron resultados.</h4>
                                            </div>
                                        }
                                    </div>
                                    <div className="white-space-32"></div>
                                    {filter.length > 12 &&
                                        <Paginator
                                            setpage={setpage.bind(this)}
                                            items={Math.ceil(filter.length / 12)}
                                        />
                                    }
                                    <div className="white-space-64"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contactanos column align-center justify-center">
                        <div className="column align-center justify-center">
                            <div className="titulo align-center justify-center">¿QUIERES PUBLICITARTE EN APPMOSPHERA?</div>
                            <br />
                            <div className="titulo2 align-center justify-center">CONTÁCTANOS PARA MÁS INFORMACIÓN</div>
                            <div className="white-space-48"></div>
							<div className="cards">
								<a href={`mailto:info@appmosphera.com`} className="email">
									<i className="fa fa-envelope"></i>
									<div className="white-space-8"></div>
									info@appmosphera.com
								</a>
								<a href={`tel:998 242 1932`} className="phone">
									<i className="fa fa-phone"></i>
									<div className="white-space-8"></div>
									998 242 1932
								</a>
							</div>
                        </div>
                        <div className="white-space-24"></div>
                        <div className="row justify-center footer-home">
                            <div className="row full overlay align-center justify-center">
                                <div className="column align-center justify-center">
                                    <div className="redes row-responsive align-center justify-center">
                                        <div className="partRedes column align-start justify-start">
                                            <div className="texto align-center justify-start">Síguenos en nuestras redes</div>
                                            <div className="iconosRedes column align-start justify-start">
                                                <div className="face row align-center justify-center">
                                                    <a href="https://www.facebook.com/AppMosphera" target="_blank" rel="noopener">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                    <a className="txtface align-center justify-center" href="https://www.facebook.com/AppMosphera">Facebook</a>
                                                </div>
                                                <br />
                                                <div className="face align-center justify-center">
                                                    <a href="https://www.instagram.com/appmosphera/?r=nametag" target="_blank" rel="noopener">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                    <a className="txtface align-center justify-center" href="https://www.instagram.com/appmosphera/?r=nametag">Instagram</a>
                                                </div>
                                                <br />
                                                <div className="face align-center justify-center">
                                                    <a href="https://www.youtube.com/channel/UCw38OvTnfQBKecU_FMTEZvw/videos" target="_blank" rel="noopener">
                                                        <i className="fab fa-youtube"></i>
                                                    </a>
                                                    <a className="txtface align-center justify-center" href="https://www.youtube.com/channel/UCw38OvTnfQBKecU_FMTEZvw/videos">Youtube</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="certificaciones column align-center justify-end">
                                            <div className="texto2 align-center justify-end">Cuidando tu información mejor que nadie.</div>
                                            <div className="imgCert align-center justify-center">
                                                <div className="ISO align-center justify-center">
                                                    <img src={SelloISO} alt=""></img>
                                                </div>
                                                <div class="IAF align-center justify-center" id="iaf-cs-mark">
										
												</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appmospheraFooter align-center justify-center">Cancún, Quintana Roo, México - AppMosphera®️ 2021</div>
                                </div>
                            </div>
                        </div>
                        <div id="whatsAppWidget" className="align-end justify-end cursor">
                            <a href="https://api.whatsapp.com/send?phone=529982421932" target="_blank" rel="noopener noreferrer">
                                <div className="waArea">
                                    <svg
                                        viewBox="0 0 32 32"
                                        width="41px"
                                        height="50px"
                                        xlinkHref="http://www.w3.org/1999/xlink"
                                    >
                                        <path
                                            d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                                            fillRule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;