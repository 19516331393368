// Pages
import micrositios from '../pages/Micrositios/micrositios';
import home from '../pages/home/home';
import Error404 from '../pages/Error404';

// Layouts
import LayoutBasic from '../layouts/LayoutBasic';

const routes = [
    {
        path: "/",
        layout: LayoutBasic,
        component: home,
        exact: true
    },
	{
		path: "/404",
        layout: LayoutBasic,
        component: Error404
    },
	{
        path: "/:id",
        layout: LayoutBasic,
        component: micrositios,
        exact: true
    },
];

export default routes;