import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import './Map.scss';

const MapContainer = (props) => {
	const style = {
		width: '100%',
		height: '100%'
	}

	const containerStyle = {
		position: 'relative',  
		width: '100%',
		height: '100%'
	}

	if (props.longitud === 0 && props.latitud === 0) {
		return null;
	}

	return (
		<div className="map">
			<Map
				containerStyle={containerStyle}
				style={style}
				google={props.google}
				zoom={16}
				initialCenter={{
					lat: props.latitud,
					lng: props.longitud
				}}>
		
				<Marker
					title="Location"
					name={'Current location'}
					position={{lat: props.latitud, lng: props.longitud}} />
			</Map>
		</div>
	);
}
 
export default GoogleApiWrapper({
	apiKey: ("AIzaSyBojssMCZfjj6SUxpd4izBfHjClyKKCZ1M")
})(MapContainer)
