import React from 'react';
// import Isotipo from '../img/Isotipo.png';
// import Color from '../img/Color.png';

const Header = () => {

    return (
        <div className="header flex justify-center align-center">
            <div className="column align-center justify-center">
                <div className="titulo align-center justify-center" >
                    <div className="column align-center justify-center">
                        <div className="cont align-center justify-start">
                            <font className="h1 align-center justify-start">
                                Bienvenido a la web de los
                                <font className="patro"> patrocinadores</font>  de AppMosphera
                                </font>
                        </div>
                        <div className="white-space-64"></div>
                        <div className="white-space-48"></div>
                        <div className="h2 align-center justify-center">
                            ¡ENTÉRATE DE NUESTRAS PROMOCIONES!
                            </div>
                        <div className="white-space-32"></div>
                        <div className="red align-center justify-end">
                            <div className="tele auto">
                                <a href="" target="_blank" rel="noopener">
                                    <i className="fas fa-phone-alt" ></i>
                                </a>
                            </div>
                            <div className="tel">998 242 1932</div>
                            <div className="tele auto">
                                <a href="" target="_blank" rel="noopener">
                                    <i className="fas fa-envelope-open"></i>
                                </a>
                            </div>
                            <div className="email">info@appmosphera.com</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;