import React, { Component, createContext } from 'react';

const Context = createContext();

class GlobalContext extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagen_zoom: "",
            close_zoom: true,
            setZoom: this.setZoom.bind(this),
        }
    }

    setZoom(imagen, close) {
        if (imagen) {
            this.setState({ imagen_zoom: imagen, close_zoom: close });
        }
        return imagen;
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        )
    }
}

const Consumer = (Component) => {
    return (props) => {
        return (
            <Context.Consumer>
                {context => <Component {...props} context={context} />}
            </Context.Consumer>
        )
    }
}

export { Consumer, GlobalContext };
