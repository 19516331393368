import React, { useState, useEffect } from 'react';
import Card from './card';

const GalleryGrid = (props) => {

  const { results, page } = props;

  const [valor, setValor] = useState([]);

  useEffect(() => {

    if (results) {

      let datos = results.slice((page - 1) * 12, page * 12);
      setValor(datos);
    };

  }, [results, page]);

  return (
    <div className="gallery-grid">
      <div className="row-responsive align-center justify-center">
        <div className="gallery-cards">

          {valor.map((v, i) => {

            return <Card item={v} key={i} />

          })}

        </div>
      </div>
    </div>
  )
};

export default GalleryGrid;