import React from 'react';
// import './Footer.scss';

export default function Footer({facebook, instagram, youtube}) {
    return (
        <div className="footer">
			{facebook && <span className="row justify-center align-center">
				<a href={facebook} target="new_tab" className="color-white">
					<i className="fa fa-facebook"></i>
				</a>
			</span>}
			{instagram &&<span className="row justify-center align-center">
				<a href={instagram} target="new_tab" className="color-white">
					<i className="fa fa-instagram"></i>
				</a>
			</span>}
			{youtube &&<span className="row justify-center align-center">
				<a href={youtube} target="new_tab" className="color-white">
					<i className="fa fa-youtube"></i>
				</a>
			</span>}
        </div>
    )
}