import React from 'react';
import { Link } from "react-router-dom";

const Card = (props) => {

    const { nombre_url,imagen_url,nombre } = props.item;
    console.log(props.item);

    return (
        <Link
            to={nombre_url ? nombre_url : '#'}
        >
            <div className="imagen-producto-home">
                <img src={imagen_url} alt={nombre} />
            </div>
        </Link>
    )
};

export default Card;