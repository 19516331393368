import React, {Component} from "react";
import {Consumer} from "../context";
import Load from "../img/load.gif";

class ModalZoom extends Component {

	close() {
		const modal = document.getElementById("image-zoom-modal");
        modal.classList.remove("fadeIn");
        modal.classList.add("animated");
		modal.classList.add("fadeOut");
		setTimeout(()=> {
			modal.style.display = "none";
			modal.classList.remove("fadeOut");
			modal.classList.remove("animated");
            modal.style.display = "none";
		},500);
	}

	render() {
		return (
			<div className="image-zoom-modal align-center justify-center" id="image-zoom-modal">
				<div className="modal-content-zoom column align-center">
					<div className="column">
						<div className="row justify-end">
							{this.props.context.close_zoom ? (
								<button id="modal-close-zoom" onClick={this.close.bind(this)}>
									&times;
								</button>
							) : ''}
						</div>
					</div>
					<div className="container justify-center align-center">
						<img
							id="img-zoom"
							className="img-zoom"
							src={this.props.context.imagen_zoom ? this.props.context.imagen_zoom : Load}
							alt="imagen"
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Consumer(ModalZoom);
