import React from 'react';

export default function LayoutBasic(props) {

    const { children } = props;

    return (
        <div className="layout-basic">
            {children}
        </div>
    );
};