import React from 'react';
import './Error404.scss';

export default function Error404() {
    return (
        <div className="error404">
            <h1>404</h1>
			<h2>Oops!</h2>
			<h2>Sitio no encontrado</h2>
        </div>
    );
};
